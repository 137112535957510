import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ClienteLogado } from '@app/_models/cliente/cliente-logado';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { LogarCliente } from '@app/_models/cliente/logar-clients';
import { DetalhesLoja } from '@app/_models/loja/detalhes-loja';
import { LojaLogada } from '@app/_models/loja/loja-logada';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CacheService, LocalStorageSaveOptions } from '../base/cache.service';
import { TenantService } from '../base/tenant.service';
import { ClienteService } from '../cliente/cliente.service';
import { LojaService } from '../loja/loja.service';
import { SplashScreenStateService } from '../splash-screen/splash-screen-state';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private lojaSubject: BehaviorSubject<LojaLogada>;
  public loja: Observable<LojaLogada>;

  private clienteSubject: BehaviorSubject<DetalhesCliente>;
  public cliente: Observable<DetalhesCliente>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private clienteService: ClienteService,
    private lojaService: LojaService,
    private cacheService: CacheService,
    private tentantService: TenantService,
    private splashScreenStateService: SplashScreenStateService
  ) {
    this.lojaSubject = new BehaviorSubject<LojaLogada>(
      this.cacheService.load('loja')
    );
    this.loja = this.lojaSubject.asObservable();

    this.clienteSubject = new BehaviorSubject<DetalhesCliente>(
      this.cacheService.load('cliente')
    );
    this.cliente = this.clienteSubject.asObservable();
  }

  public get lojaLogada(): DetalhesLoja {
    return this.cacheService.load('loja');
  }

  public get lojaLogadaToken(): LojaLogada {
    return this.cacheService.load('loja-token');
  }

  public get consumidorLogadoToken() {
    return this.cacheService.load('consumidor-token');
  }

  get lojaEstaAutenticada() {
    return this.lojaLogadaToken && this.lojaLogadaToken.AccessToken;
  }

  get clienteEstaLogado() {
    return this.clienteSubject.value;
  }

  autenticaLoja() {
    let url = this.tentantService.getTenant();
    url = `${url.split('/')[0]}//${url.split('/')[2]}/`;
    url = url.replace(/[/]/g, '%2F');
    url = url.replace(/:/g, '%3A');
    let tempoExpiracao;

    this.splashScreenStateService.setTextLoading("Autenticando");

    return this.http
      .get<any>(`${environment.apiUrl}Cliente/Cardapio/Autenticacao/${url}`)
      .pipe(
        catchError((err) => {
          this.router.navigate(['servidor-indisponivel']);
          return of();
        }),
        switchMap((e) => {
          const getTOken =
            url == 'http%3A%2F%2Flocalhost%3A4200%2F'
              ? {
                  ClientId: 'B8D8F9FA-6E55-474D-AB81-363F2B6D29B5',
                  ClientSecret: '6248A46E-DD7D-43A0-840D-33BC51D87A36',
                  CNPJ: '00584537000146',
                  CodLoja: 1,
                }
              : {
                  ClientId: 'B8D8F9FA-6E55-474D-AB81-363F2B6D29B5',
                  ClientSecret: '6248A46E-DD7D-43A0-840D-33BC51D87A36',
                  CNPJ: e.CNPJ,
                  CodLoja: e.CodLoja,
                };

          return this.http
            .post<LojaLogada>(`${environment.apiUrl}oauth/token`, getTOken)
            .pipe(
              catchError((err) => {
                this.splashScreenStateService.stop(); // Remove a animação de loading ao terminar o carregamento das opções
                this.router.navigate(['servidor-indisponivel']);
                return of();
              })
            )
            .pipe(
              switchMap((tokenloja: LojaLogada) => {
                if (tokenloja) {
                  this.cacheService.save(
                    new LocalStorageSaveOptions(
                      'loja-token',
                      { AccessToken: tokenloja.AccessToken },
                      tokenloja.expireIn
                    )
                  );
                  tempoExpiracao = tokenloja.expireIn;

                  // Comentário por Felipe Lopes
                  // Imagino que:
                  // Faz a conversão para milisegundos e remove 5000 milisegundos
                  // para termos uma 'folga' de 5000 milisegundos antes da expiração. Assim, não
                  // estourando erro para o usuário.
                  const tempo = tempoExpiracao * 1000 - 5000;
                  setInterval(() => {
                    this.autenticaLoja().subscribe();
                  }, tempo);

                  this.splashScreenStateService.setTextLoading("Carregando personalizações");

                  return this.lojaService.buscaDetalhesLoja();
                }
              })
            )
            .pipe(
              map((loja) => {
                this.cacheService.save(
                  new LocalStorageSaveOptions('loja', loja, tempoExpiracao)
                );

                this.lojaSubject.next(loja);

                // Remove a animação de loading ao terminar o carregamento das opções
                this.splashScreenStateService.stop();

                return loja;
              })
            );
        })
      );
  }

  loginCliente(clienteParaLogar: LogarCliente) {
    return this.clienteService
      .logar(clienteParaLogar.Senha, clienteParaLogar.Identificacao)
      .pipe(
        map((cliente: ClienteLogado) => {
          if (cliente.EmailConfirmado) {
            this.salvaConsumidorCache(cliente, cliente.JWTTokenExpiracao);
          }

          this.salvarTokenConsumidor(
            cliente.JWTToken,
            cliente.JWTTokenExpiracao
          );
          return cliente;
        })
      );
  }

  salvaConsumidorCache(cliente, tempoExpiracao?: number) {
    this.cacheService.save(
      new LocalStorageSaveOptions('cliente', cliente, tempoExpiracao)
    );
    this.clienteSubject.next(cliente);
  }

  salvarTokenConsumidor(tokenConsumidor: string, tempoExpiracao?: number) {
    this.cacheService.save(
      new LocalStorageSaveOptions(
        'consumidor-token',
        { Token: tokenConsumidor },
        tempoExpiracao
      )
    );
  }

  atualizaClienteLogado(cliente, tempoExpiracao?: number) {
    cliente.Senha = null;
    this.cacheService.save(
      new LocalStorageSaveOptions('cliente', cliente, tempoExpiracao)
    );
    this.clienteSubject.next(cliente);
  }

  get tokenConsumidor() {
    return this.cacheService.load('consumidor-token');
  }

  removerTokenConsumidor() {
    this.cacheService.remove('consumidor-token');
  }

  logout() {
    // Remove o cliente do cache, observer e limpa o token da API
    this.cacheService.remove('cliente');
    this.cacheService.remove('consumidor-token');
    this.clienteSubject.next(null);

    // Remove o endereço do observer
    this.clienteService.atualizarEnderecoSelecionadoSubject(null);
    this.clienteService.enderecos = null;

    // Limpa a tabela de endereços mantido no navegador
    this.clienteService.limparEnderecoSelecionadoSubject();

    // Redireciona para a home
    this.router.navigate(['/']);
  }
}
