import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IndoorService {

  public indoor: boolean = null;
  public numeroMesa: string = null;

  constructor() { }
}
