<div class="menu">
  <ul>
    <li *ngFor="let item of itensCarrinho">
      <figure>
        <img [src]="imagemURL(item) ? imagemURL(item) : 'https://noxmobarquivos.blob.core.windows.net/cardapio/assets/images/menu-thumb-placeholder.jpg'" data-src="img/menu-thumb-1.jpg" alt=""
          width="50" height="50" class="lazy">
        </figure>
        <h5>
          <h5 style="margin: 0;">
            {{ item.quantidade }}x
            {{item.mercadoria.NomeCurto}}
          </h5>
          <span style="font-weight: bold;">
            {{ (item.mercadoria.PrecoVenda | currency:'BRL') }}
          </span>
        </h5>
        <mat-icon style="position: absolute; right: 0; top: 0;" (click)="removerItemCarrinho(item.codigoItem)" class="material-icons-outlined">
          remove_circle_outline
        </mat-icon>
    </li>
  </ul>
  <div class="total_drop">
    <div class="clearfix add_bottom_15" style="color: black;">
      <h4>Total</h4>
      <h4 style="justify-content: end;">{{ (valorTotal | currency:'BRL') }}</h4>
    </div>
    <button (click)="abrirCarrinho()" class="btn_1 full-width mb_5">
      Ver sacola
    </button>
  </div>
</div>
