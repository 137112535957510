<header
  [ngClass]="{'header black_nav clearfix element_to_stick': rotaAtualHome, 'header_in vertical-align-center clearfix': !rotaAtualHome, 'sticky': deveMostrarNavFixed}">

  <div class="container vertical-align-center">
    <div *ngIf="logo" id="logo" style="margin: unset !important;">
      <a routerLink="" style="font-size: 1.8125rem !important;">
        <img *ngIf="lojaLogada" [src]="logo" width="auto" height="40" [alt]="'Logo do ' + restaurante.Nome">
      </a>
    </div>

    <div *ngIf="!logo && lojaLogada" class="vertical-align-center" style="outline: none; font-size: 20px; cursor: pointer;">
      <button class="vertical-align-center" routerLink="">
        <mat-icon class="material-icons-outlined">home</mat-icon>
        <h4 *ngIf="!isMobile" class="vertical-align-center" style="margin-left: 0.5rem;">{{ lojaLogada.Nome }}</h4>
      </button>
    </div>

    <div class="layer"></div><!-- Opacity Mask Menu Mobile -->
    <ul id="top_menu">

      <li *ngIf="!indoor && (restaurante.HabilitarEntrega || restaurante.HabilitarPraRetirar)">
        <div class="meu-endereco clearfix">
          <button class="vertical-align-center" (click)="abrirModalMetodoEntrega()">
            <h4 class="vertical-align-center" *ngIf="!enderecoPadrao?.Rua && !pedidoEhRetiradaBalcao">Método de entrega</h4>
            <h4 class="vertical-align-center" *ngIf="pedidoEhRetiradaBalcao">Vou retirar</h4>
            <h4 class="vertical-align-center" *ngIf="enderecoPadrao?.Rua && !pedidoEhRetiradaBalcao">{{enderecoPadrao?.Rua | slice:0:15}}, {{enderecoPadrao?.Numero}}</h4>
            <mat-icon class="material-icons-outlined">expand_more</mat-icon>
          </button>
        </div>
      </li>

      <li *ngIf="!usuarioLogado && (restaurante.HabilitarEntrega || restaurante.HabilitarPraRetirar || restaurante.HabilitarAgendamento)">
        <div class="dropdown user clearfix">
          <button class="vertical-align-center" (click)="abrirModalLogin()">
            <h4 class="vertical-align-center">Entrar</h4>
            <mat-icon class="icon_person material-icons-outlined">account_circle</mat-icon>
            <mat-icon class="icon_arrow material-icons-outlined">expand_more</mat-icon>
          </button>
        </div>
      </li>

      <li *ngIf="usuarioLogado">
        <div class="dropdown user clearfix">
          <button class="vertical-align-center" [matMenuTriggerFor]="menu_user">
            <h4 class="vertical-align-center">{{ usuarioLogado.Nome }}</h4>
            <mat-icon class="icon_person material-icons-outlined">account_circle</mat-icon>
            <mat-icon class="icon_arrow material-icons-outlined" iconPositionEnd>expand_more</mat-icon>
          </button>
          <mat-menu #menu_user="matMenu">
            <button class="vertical-align-center" mat-menu-item routerLink="minha-conta/dados-cadastrais">
              <mat-icon class="material-icons-outlined icon-dropdown">settings</mat-icon>
              Alterar dados
            </button>
            <button class="vertical-align-center" mat-menu-item routerLink="minha-conta/alterar-senha">
              <mat-icon class="material-icons-outlined icon-dropdown">lock_open</mat-icon>
              Alterar senha
            </button>
            <button class="vertical-align-center" mat-menu-item routerLink="minha-conta/meus-pedidos">
              <mat-icon class="material-icons-outlined icon-dropdown">receipt</mat-icon>
                Meus pedidos
            </button>
            <button class="vertical-align-center" mat-menu-item (click)="logout()">
              <mat-icon class="material-icons-outlined icon-dropdown">logout</mat-icon>
              Sair
            </button>
          </mat-menu>
        </div>
      </li>

      <li *ngIf="usuarioLogado && !telaConclusaoPedido && !isMobile">
        <button class="vertical-align-center" style="position: relative;" [matMenuTriggerFor]="menu_carrinho">
          <mat-icon class="material-icons-outlined color-8-badge" [matBadge]="quantidadeItensSacola">
            shopping_bag
          </mat-icon>
        </button>
        <mat-menu #menu_carrinho="matMenu" xPosition="before">
          <app-dropdown-itens-carrinho></app-dropdown-itens-carrinho>
        </mat-menu>
      </li>

      <li *ngIf="usuarioLogado && !telaConclusaoPedido && isMobile">
        <button class="vertical-align-center" style="position: relative;" (click)="abrirModalResumo()">
          <mat-icon class="material-icons-outlined color-8-badge" [matBadge]="quantidadeItensSacola">
            shopping_bag
          </mat-icon>
        </button>
      </li>
    </ul>
  </div>
</header>
