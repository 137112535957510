import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { JwtInterceptor } from './_helpers/jwt-interceptor';
import { ErrorInterceptor } from './_helpers/error-interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './_components/home/home.module';
import { NavbarComponent } from './_components/compartilhados/navbar/navbar.component';
import { FooterComponent } from './_components/compartilhados/footer/footer.component';
import { VoltarAoTopo } from './_components/compartilhados/voltar-ao-topo/voltar-ao-topo.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PedidoModule } from './_components/pedido/pedido.module';
import { UsuarioModule } from './_components/cliente/usuario.module';
import { NgxMaskModule } from 'ngx-mask';
import { maskConfig } from './_configs/mask-config';
import { ModalLoginComponent } from './_components/cliente/modal-login/modal-login.component';
import { ServidorIndisponivelComponent } from './_components/compartilhados/servidor-indisponivel/servidor-indisponivel.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import localePT from '@angular/common/locales/pt';
import { ToastrModule } from 'ngx-toastr';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InViewportModule } from 'ng-in-viewport';
import { ModalImagemComponent } from './_components/compartilhados/modal-imagem/modal-imagem.component';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { dbConfig } from './_configs/db-config';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SplashScreenComponent } from './_components/splash-screen/splash-screen.component';
import { SplashScreenStateService } from './_services/splash-screen/splash-screen-state';
import { LottieModule } from 'ngx-lottie';
import { GoogleMapsModule } from '@angular/google-maps'
import { MatStepperModule } from '@angular/material/stepper';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilaEsperaService } from './_services/fila-espera/fila-espera.service';

import player from 'lottie-web';

export function playerFactory() {
  return player;
}

registerLocaleData(localePT, 'pt');

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    VoltarAoTopo,
    ModalLoginComponent,
    ServidorIndisponivelComponent,
    ModalImagemComponent,
    SplashScreenComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    AppRoutingModule,
    HomeModule,
    PedidoModule,
    UsuarioModule,
    PedidoModule,
    MatTabsModule,
    MatExpansionModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(maskConfig),
    ToastrModule.forRoot(),
    NgxIndexedDBModule.forRoot(dbConfig),
    CreditCardDirectivesModule,
    LazyLoadImageModule,
    InViewportModule,
    NgbModule,
    NgxSkeletonLoaderModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    GoogleMapsModule,
    MatStepperModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatTooltipModule
  ],
  exports: [
    MatStepperModule,
  ],
  providers: [
    SplashScreenStateService,
    FilaEsperaService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('737967125253-s0kpvtcuo651t7vrcoo7b7v7l5mf3krp.apps.googleusercontent.com')
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('463416391316024')
          }
        ]
      } as SocialAuthServiceConfig,

    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
