import { Component, OnInit } from '@angular/core';
import { DetalhesCliente } from '@app/_models/cliente/detalhes-cliente';
import { Endereco } from '@app/_models/endereco/endereco';
import { ModalAlertService } from '@app/_services/base/alert.service';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LoginService } from '@app/_services/login/login.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResultadoPesquisaEnderecoService } from '@app/_services/endereco/resultado-pesquisa-endereco.service';
import { FormaRetiradaPedido } from '@app/_enums/forma-retirada-pedido';
import { ModalCadastroEnderecoComponent } from '../modal-cadastro-endereco/modal-cadastro-endereco.component';
import { SacolaService } from '@app/_services/sacola/sacola.service';

@Component({
  selector: 'app-modal-selecao-endereco-entrega',
  templateUrl: './modal-selecao-endereco-entrega.component.html',
  styleUrls: ['./modal-selecao-endereco-entrega.component.scss']
})
export class ModalSelecaoEnderecoEntregaComponent implements OnInit {

  cliente: DetalhesCliente;
  enderecosCadastrados: Endereco[];
  coordenadas: google.maps.LatLngLiteral;
  estaAtualizando = false;

  constructor(
    public activeModal: NgbActiveModal,
    private clienteService: ClienteService,
    private modalService: NgbModal,
    private loginService: LoginService,
    private alertaService: ModalAlertService,
    private enderecoService: ResultadoPesquisaEnderecoService,
    private sacolaService: SacolaService,
  ) {
    if (!this.enderecosCadastrados) {
      this.listaEnderecos();
    }

    this.loginService.cliente.subscribe(cliente => {
      this.cliente = cliente;
    });
  }

  ngOnInit(): void { }


  removeEndereco(endereco: Endereco) {
    this.alertaService.mostraAlerta("Você tem certeza que deseja remover o endereço?", 'warning', 'Remover', () => {
      this.clienteService.removeEndereco(endereco.Codigo).subscribe(e => {

        if (endereco.Principal)
          this.clienteService.atualizarEnderecoSelecionadoSubject(null);

        this.listaEnderecos();
      });
    },
      () => this.listaEnderecos(),
      true);
  }

  abrirModalAdicionarEndereco(endereco?: Endereco) {
    const modalRef = this.modalService.open(ModalCadastroEnderecoComponent, { size: 'xl', scrollable: true, centered: true });
    modalRef.componentInstance.step = 1;
    modalRef.componentInstance.enderecoEditar = endereco;
  }

  marcarEnderecoPrincipal(endereco: Endereco) {
    this.enderecosCadastrados.forEach(e => {
      if (e.Codigo != endereco.Codigo) {
        e.Principal = false;
      } else {
        endereco.Principal = true;
        this.clienteService.atualizaEndereco(endereco).subscribe(e => {
          this.listaEnderecos();
        });
      }
    });
  }

  private listaEnderecos() {
    this.clienteService.listaEnderecos().subscribe(enderecos => {
      this.enderecosCadastrados = enderecos.value;
    })
  }

  selecionarEndereco(endereco: Endereco) {
      
    // Caso o consumidor esteja logado, atualizamos os dados diretamente na API
    if (this.usuarioLogado) {
      this.clienteService.atualizaEndereco(endereco).subscribe(e => {
        this.clienteService.atualizarEnderecoSelecionadoSubject(endereco);
        this.clienteService.avisaAtualizacaoEndereco();
        this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
        this.sacolaService.selecionarEndereco(endereco);
        this.fecharModal();
        this.alertaService.mostraAlerta("Endereço alterado com sucesso!", 'success');
      });
    } else { 
      this.clienteService.atualizarEnderecoNoCache(endereco);
      this.clienteService.alterarFormaRetirada(FormaRetiradaPedido.Entrega);
      this.sacolaService.selecionarEndereco(endereco);
      this.fecharModal();
      this.alertaService.mostraAlerta("Endereço alterado com sucesso!", 'success');
    }
  }

  fecharModal(endereco?: Endereco) {
    this.activeModal.close(endereco);
  }

  get usuarioLogado() {
    return this.loginService.consumidorLogadoToken ? this.loginService.clienteEstaLogado : null;
  }
}
