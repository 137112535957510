import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './_components/compartilhados/not-found/not-found.component';
import { HomeComponent } from './_components/home/home.component';
import { AcompanhamentoPedidoComponent } from './_components/pedido/acompanhamento-pedido/acompanhamento-pedido.component';
import { PedidoComponent } from './_components/pedido/pedido/pedido.component';
import { CadastroComponent } from './_components/cliente/cadastro/cadastro.component';
import { LoginComponent } from './_components/cliente/login/login.component';
import { AuthGuard } from './_helpers/auth-guard.service';
import { ServidorIndisponivelComponent } from './_components/compartilhados/servidor-indisponivel/servidor-indisponivel.component';
import { DadosCadastraisComponent } from './_components/cliente/dados-cadastrais/dados-cadastrais.component';
import { AlterarSenhaComponent } from './_components/cliente/alterar-senha/alterar-senha.component';
import { MeusPedidosComponent } from './_components/cliente/meus-pedidos/meus-pedidos.component';
import { RestauranteResolver } from './_components/restaurante/restaurante.resolver';
import { FilaEsperaComponent } from './_components/restaurante/fila-espera/fila-espera.component';

const restaurantesModule = () =>
  import('./_components/restaurante/restaurante.module').then(
    (x) => x.RestauranteModule
  );

const routes: Routes = [
  { path: '', loadChildren: restaurantesModule },
  {
    path: 'home',
    component: HomeComponent,
    resolve: { loja: RestauranteResolver },
  },
  {
    path: 'Confirmacao/Consumidor/:codigo/confirmar/:token',
    component: LoginComponent,
    resolve: { loja: RestauranteResolver },
  },
  {
    path: 'Reconfigurar/Consumidor/:codigo/confirmar/:token',
    component: AlterarSenhaComponent,
    resolve: { loja: RestauranteResolver },
  },
  {
    path: 'login',
    component: LoginComponent,
    resolve: { loja: RestauranteResolver },
  },
  {
    path: 'cadastro',
    component: CadastroComponent,
    resolve: { loja: RestauranteResolver },
  },
  { path: 'restaurante', loadChildren: restaurantesModule },
  {
    path: 'pedido',
    component: PedidoComponent,
    resolve: { loja: RestauranteResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'confirmacao',
    component: AcompanhamentoPedidoComponent,
    resolve: { loja: RestauranteResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'acompanhamento/:codigo',
    component: AcompanhamentoPedidoComponent,
    resolve: { loja: RestauranteResolver },
    canActivate: [AuthGuard],
  },
  { path: 'servidor-indisponivel', component: ServidorIndisponivelComponent },
  {
    path: 'minha-conta/dados-cadastrais',
    component: DadosCadastraisComponent,
    resolve: { loja: RestauranteResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'minha-conta/alterar-senha',
    component: AlterarSenhaComponent,
    resolve: { loja: RestauranteResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'minha-conta/meus-pedidos',
    component: MeusPedidosComponent,
    resolve: { loja: RestauranteResolver },
    canActivate: [AuthGuard],
  },
  {
    path: 'fila-espera/:codigo',
    component: FilaEsperaComponent,
    resolve: { loja: RestauranteResolver },
  },

  { path: '**', component: NotFoundComponent },

  // { path: 'users', loadChildren: usersModule, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
