import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalMensagemComponent } from '@app/_components/compartilhados/modal-mensagem/modal-mensagem.component';
import { StringHelper } from '@app/_helpers/string-helper';
import { OTP } from '@app/_models/cliente/otp';
import { ClienteService } from '@app/_services/cliente/cliente.service';
import { LoginService } from '@app/_services/login/login.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';

@Component({
  selector: 'app-modal-otp',
  templateUrl: './modal-otp.component.html',
  styleUrls: ['./modal-otp.component.scss'],
})
export class ModalOtpComponent {
  @ViewChild('timerOTP', { static: false }) public timerOTP: CountdownComponent;

  formOTP: UntypedFormGroup;
  numeroCelular: string;
  erro: string;
  token: string;
  returnUrl: string;
  podeContinuar: boolean;
  numeroReenviosOTP = 0;
  reenvioOTPBloqueado: boolean = true;
  timerEmAndamento: boolean = true;

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
    private loginService: LoginService,
    private clienteService: ClienteService,
    private stringHelper: StringHelper,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  fecharModal() {
    this.activeModal.close();
  }

  onOtpChange(e) {
    // Caso o usuário tenha digitado os 4 números da OTP, habilitamos o botão de envio.
    if (e.length === 4) {
      this.token = e;
      this.podeContinuar = true;
    } else {
      this.podeContinuar = false;
    }
  }

  validarOTP() {
    if (this.podeContinuar) {
      const otp: OTP = {
        Numero: this.numeroCelular,
        Token: this.token,
      };

      this.clienteService.validarSMSIdentidade(otp).subscribe(
        (login) => {
          this.fecharModal();

          // Faz o login do consumidor com os dados retornados da API
          this.loginService.salvaConsumidorCache(
            login,
            login.JWTTokenExpiracao
          );

          this.loginService.salvarTokenConsumidor(
            login.JWTToken,
            login.JWTTokenExpiracao
          );

          // Redireciona o usuário para a home
          this.router.navigateByUrl(this.returnUrl);

          // Exibe mensagem de logado com sucesso
          document.body.style.position = 'fixed';

          const modalRef = this.modalService.open(ModalMensagemComponent, {
            backdrop: 'static',
            keyboard: false
          });

          modalRef.componentInstance.titulo = 'Login realizado';
          modalRef.componentInstance.centralizarTexto = true;
          modalRef.componentInstance.mensagem = "Seu login foi realizado com sucesso!";
          modalRef.componentInstance.imagem = "/assets/images/login.svg";
          
          modalRef.result.then((e) => {
            document.body.style.position = '';
          }).catch((e) => {
            document.body.style.position = '';
          });

        },
        (err) => (this.erro = this.stringHelper.formataMensagemErro(err))
      );
    }
  }

  reenviarOTP() {
    this.numeroReenviosOTP++;
    this.reenvioOTPBloqueado = false;
    this.timerEmAndamento = true;

    if (this.numeroReenviosOTP >= 4) {
      this.erro = 'Atingiu o limite de reenvios';
    }

    if (!this.reenvioOTPBloqueado) {
      this.iniciarOTPTimer();

      const dadosValidacaoCelular: OTP = {
        Numero: this.numeroCelular,
        Token: '',
      };
      this.clienteService
        .enviarSMSParaVerificacaoIdentidade(dadosValidacaoCelular)
        .subscribe(
          () => {
            // Reinicia o timer para reenviar o OTP.
            this.timerOTP.restart();
          },
          (err) => {
            this.erro =
              'Falha ao reenviar o código por SMS. Tente novamente mais tarde.';
          }
        );
    }
  }

  iniciarOTPTimer() {
    this.timerEmAndamento = true;
    this.timerOTP.restart();
  }

  statusOTPTimer(e) {
    if (e.action == 'done') {
      this.timerEmAndamento = false;
      this.timerOTP.stop();
      this.timerOTP.pause();
    }
  }

  get configTimer() {
    const config: CountdownConfig = {
      demand: false,
      leftTime: 180, // 3 minutos
    };

    return config;
  }

  get mostrarTimer() {
    if (this.timerEmAndamento) return false;
    else return true;
  }
}
